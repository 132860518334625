/***
 *
 *   PUBLIC CLIENT FORM
 *   CAPTURES CLIENT INFORMATION
 *
 **********/

import React, { useCallback, useState } from "react";
import { Row, useAPI, Card, Footer, Image } from "components/lib";
import { StylesManager, Model } from "survey-core";
import { useParams } from "react-router-dom";
import { Survey } from "survey-react-ui";
import clientFormJson from "./clientFormJson";
import "survey-core/defaultV2.css";
import Axios from "axios";
import { v4 as uuidv4 } from "uuid";

StylesManager.applyTheme("defaultV2");

export function PublicClientView(props) {
  const { id } = useParams();
  const [clientId, setClientId] = useState(uuidv4());
  console.log("clientId", clientId);

  const account = useAPI(`/api/account/public/${id}`);
  // clientFormJson.pages[1].elements[14].choices = account?.data?.locations.map(
  //   (l) => {
  //     return { value: l.id, text: l.name };
  //   }
  // );
  // console.log("elements", clientFormJson.pages[1].elements[36]);
  clientFormJson.pages[1].elements[47].templateElements[0].choices =
    account?.data?.locations.map((l) => {
      return { value: l.id, text: l.name };
    });
  console.log("json", clientFormJson);
  console.log("locations", account?.data?.locations);
  const survey = new Model(clientFormJson);
  // survey.getQuestionByName("mainReason").choices = survey
  //   .getQuestionByName("mainReason")
  //   .choices.filter(
  //     (c) => account?.data?.exclusions?.mainReason?.includes(c.value) === false
  //   );
  console.log("account", account);
  if (account?.loading === false && account?.data?.exclusions?.hideTelehealthPreference) {
    console.log("hiding telehealth preference");
    survey.getQuestionByName("telehealthPreference").visible = false;
  }

  const categories = [
    "mainReason",
    "therapySubCategories",
    "assessments",
    "neurodivergenceSubCategories",
    "mentalHealthSubCategories",
    "generalHealthSubCategories",
    "traumaSubCategories",
    "personalSubCategories",
    "genderSubCategories",
    "relationshipSubCategories",
    "educationalSubCategories",
    "addictionSubCategories",
    "workSubCategories",
    "legalSubCategories",
    "subPersonalityDisorders",
    "eatingSubCategories",
    "anxietySubCategories",
    "whichSpeciality",
    "occupationalTherapyAssessmentSubCategories",
    "occupationalTherapySubCategories",
    "groupTherapySubCategories",
    "whoPays",
    "service"
  ];

  categories.forEach((category) => {
    survey.getQuestionByName(category).choices = survey
      .getQuestionByName(category)
      .choices.filter(
        (c) => !account?.data?.exclusions?.[category]?.includes(c.value)
      );
  });

  survey.focusFirstQuestionAutomatic = true;
  survey.title = account?.data?.name + " - Enquiry Form";

  //add locations

  const surveyComplete = useCallback(async (sender) => {
    await saveSurveyResults("/api/public/client/" + id, sender.data);
  }, []);

  async function saveSurveyResults(url, json) {
    console.log("saveSurveyResults", url, json);
    try {
      await Axios.post(url, { id: clientId, ...json })
      window.location.href = "/thanks";
    } catch (error) {
      console.log("error", error);
    }
  }

  const uploadFiles = useCallback(async (sender, options) => {
    // console.log("sender", sender);
    // console.log("options", options);
    var formData = new FormData();
    options.files.forEach(function (file) {
      formData.append("file", file);
      formData.append("id", clientId);
    });

    const uploadResponse = await Axios.post("/api/utility/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("uploadResponse", uploadResponse);

    options.callback(
      "success",
      options.files.map(function (file) {
        console.log("file", file);
        return {
          file: file,
          content: uploadResponse?.data?.data?.files?.find(
            (f) => f.filename === file.name
          )?.url,
        };
      })
    );

    // return {
    //   files: options.files.map(function (file) {
    //     console.log("file", file);
    //     console.log("uploadResponse", uploadResponse);
    //     return {
    //       file: file,
    //       content: uploadResponse?.data?.data?.url,
    //     };
    //   }),
    // };

    // var xhr = new XMLHttpRequest();
    // xhr.open("POST", "/api/utility/upload");
    // xhr.onload = function () {
    //   var data = JSON.parse(xhr.responseText);
    //   // console.log("data", data.url);
    //   options.callback(
    //     "success",
    //     options.files.map(function (file) {
    //       // console.log("file", file);
    //       return {
    //         file: file,
    //         content: data.url,
    //       };
    //     })
    //   );
    // };
    // console.log("form data", formData);
    // xhr.send(formData);
  });

  survey.onComplete.add(surveyComplete);
  survey.onUploadFiles.add(uploadFiles);

  if (account?.data?.active === false) {
    return (
      <Row title="Oops">
        <Card title="Account Not Found"></Card>
      </Row>
    );
  }

  if (account?.loading) {
    return (
      <Row title="Loading">
        <Card title="Loading Account"></Card>
      </Row>
    );
  }

  return (
    <>
      <Card className={`w-screen bg-${account?.data?.settings?.color || "#F0AA61"} flex flex-col justify-center items-center`}>
        <Row>
          <span className="flex justify-center items-center">
            <Image
              source={`${account?.data?.settings?.logoUrl?.[0]?.content || "https://i.imgur.com/4DhZKRy.png"}`}
            />
          </span>
          <Card className="flex-grow">{!account?.loading && <Survey model={survey} />}</Card>
          <Card>
            <Footer />
          </Card>
        </Row>
      </Card>
    </>
  );
}
